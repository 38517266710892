<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="$store.state.app.loading"
      no-wrap
      :variant="$store.state.appConfig.layout.skin === 'dark' ? 'black' : 'white'"
    />
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('message.Error fetching user data') }}
      </h4>
      <div class="alert-body">
        {{ $t('message.No user found with this user id') }}
        <b-link
          class="alert-link"
          :to="{ name: 'users'}"
        >
          {{ $t('message.Check User List for other users') }}
        </b-link>
      </div>
    </b-alert>

    <!-- <b-tabs
      v-if="userData"
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">{{ $t('Profile') }}</span>
        </template> -->
        <user-edit-tab-account
          v-if="userData"
          :user-data="userData"
        />
      <!-- </b-tab>
    </b-tabs> -->
  </component>
</template>

<script>
import {
  // BTab, BTabs,
  BCard, BAlert, BLink, BOverlay,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import usersStoreModule from '../usersStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'

export default {
  components: {
    // BTab,
    // BTabs,
    BCard,
    BAlert,
    BLink,
    BOverlay,

    UserEditTabAccount,
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-users'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, usersStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-users/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        const { data } = response
        userData.value = data
      })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
    }
  },
}
</script>

<style>

</style>
