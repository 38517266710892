<template>
  <div>

    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="newData.avatar && server + '/' + newData.avatar"
          :text="avatarText(newData.fio)"
          :variant="`light-${resolveUserRoleVariant(newData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ newData.fio }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">{{ newData.avatar ? $t('Update') : $t('Upload') }}</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- User Info: Input Fields -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>

          <!-- Field: last Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Фамилия"
              label-for="last_name"
            >
              <b-form-input
                id="last_name"
                v-model="newData.last_name"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Firt Name -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Имя"
              label-for="first_name"
            >
              <b-form-input
                id="first_name"
                v-model="newData.first_name"
              />
            </b-form-group>
          </b-col>

          <!-- Field: surname -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Отчество"
              label-for="surname"
            >
              <b-form-input
                id="surname"
                v-model="newData.surname"
              />
            </b-form-group>
          </b-col>

          <!-- Field: login -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Логин"
              label-for="login"
            >
              <b-form-input
                id="login"
                v-model="newData.login"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="newData.email"
                type="email"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Телефон"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="newData.phone"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Статус"
              label-for="user-status"
            >
              <v-select
                v-model="newData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="val => parseInt(val.value)"
                :clearable="false"
                input-id="user-status"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Role -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Роль"
              label-for="role"
            >
              <v-select
                v-model="newData.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="role"
              />
            </b-form-group>
          </b-col>

          <b-col v-if="newData.role === 'client'">
            <b-form-group
              label="Тариф"
              label-for="role"
            >
              <v-select
                v-model="newData.tariff"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tariffsOptions"
                :get-option-label="option => option.name"
                :clearable="false"
                input-id="tariff"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          {{ $t('Save Changes') }}
        </b-button>
        <b-button
          variant="outline-secondary"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          {{ $t('Reset') }}
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BForm,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import {
  // ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,

    ValidationObserver,
    // ValidationProvider,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      resolveUserRoleVariant,
      roleOptions,
      tariffsOptions,
      statusOptions,
      fetchTariffs,
    } = useUsersList()

    const server = process.env.VUE_APP_SERVER

    const fio = props.userData.fio.split(' ')

    const newData = ref(JSON.parse(JSON.stringify(props.userData)))

    if (fio && fio.length > 0) {
      if (fio[0]) {
        // eslint-disable-next-line prefer-destructuring
        newData.value.last_name = fio[0]
      }
      if (fio[1]) {
        // eslint-disable-next-line prefer-destructuring
        newData.value.first_name = fio[1]
      }
      if (fio[2]) {
        // eslint-disable-next-line prefer-destructuring
        newData.value.surname = fio[2]
      }
    }

    const resetuserData = () => {
      newData.value = JSON.parse(JSON.stringify(props.userData))

      if (fio && fio.length > 0) {
        if (fio[0]) {
          // eslint-disable-next-line prefer-destructuring
          newData.value = {
            ...newData.value,
            last_name: fio[0],
          }
        }
        if (fio[1]) {
          // eslint-disable-next-line prefer-destructuring
          newData.value = {
            ...newData.value,
            first_name: fio[1],
          }
        }
        if (fio[2]) {
          // eslint-disable-next-line prefer-destructuring
          newData.value = {
            ...newData.value,
            surname: fio[2],
          }
        }
      }
    }

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, () => {
      store.dispatch('app-users/uploadFile', {
        ...props.userData,
        avatar: refInputEl.value.files[0],
      }).then(res => {
        const { data } = res

        if (data && data.filename) {
          // eslint-disable-next-line no-param-reassign, prefer-template
          newData.value.avatar = data.filename + '?' + Date.now()
        }
      })
    })

    const onSubmit = () => {
      newData.value.fio = `${newData.value.last_name} ${newData.value.first_name || ''} ${newData.value.surname || ''}`.trim()
      store.dispatch('app-users/updateUser', newData.value)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    fetchTariffs()

    return {
      server,

      newData,

      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      tariffsOptions,
      statusOptions,

      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
